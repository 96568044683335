import React, { useState, useEffect } from "react";
import { Col, Form, Input, Button, Table, Select } from "antd";

const Waste = () => {
  const [selected, setSelected] = useState(null);
  const [form] = Form.useForm();
  const [data, setData] = useState([
    {
      name: "Aerosoles",
      lastname: "Unidad",
      type: "4",
    },
    {
      name: "Filtros",
      lastname: "Unidad",
      type: "2",
    },
    {
      name: "Baterías ge",
      lastname: "Kilo",
      type: "3",
    },
    {
      name: "Envases de adhesivo",
      lastname: "Litros",
      type: "10",
    },
  ]);
  useEffect(() => {
    if (selected) {
      form.setFieldValue("name", selected.name);
      form.setFieldValue("lastname", selected.lastname);
      form.setFieldValue("type", selected.type);
    }
  }, [selected]);
  console.log(selected);
  return (
    <>
      <Col span={16} style={{ padding: "30px" }}>
        <Table
          dataSource={data}
          columns={[
            { title: "Nombre", dataIndex: "name" },
            { title: "Tipo medida", dataIndex: "lastname" },
            { title: "Cantidad", dataIndex: "type" },
            {
              render: (x) => (
                <>
                  <Button style={{ marginRight: "10px" }} type="primary" danger>
                    Eliminar
                  </Button>
                  <Button type="primary" onClick={() => setSelected(x)}>
                    Retiro
                  </Button>
                </>
              ),
            },
          ]}
        />
      </Col>
      <Col span={8} style={{ padding: "30px" }}>
        <Form
          form={form}
          layout="vertical"
          onFinish={(values) => {
            if (selected) {
            } else {
              setData([...data, values]);
            }
          }}
          initialValues={selected}
        >
          <Form.Item label="Nombre" name="name">
            <Input />
          </Form.Item>
          <Form.Item label="Tipo medicion" name="lastname">
            <Input />
          </Form.Item>
          <Form.Item label="Cantidad" name="type">
            <Input />
          </Form.Item>

          <Form.Item>
            <Button
              htmlType="submit"
              type="primary"
              style={{ marginRight: "10px" }}
            >
              Crear
            </Button>
            <Button onClick={() => form.resetFields()}>Limpiar</Button>
          </Form.Item>
        </Form>
      </Col>
    </>
  );
};

export default Waste;
