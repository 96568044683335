import React, { useState, useEffect } from "react";
import { Col, Form, Input, Button, Table, Select } from "antd";

const DriversTrucks = () => {
  const [selected, setSelected] = useState(null);
  const [selected2, setSelected2] = useState(null);
  const [form] = Form.useForm();
  const [data, setData] = useState([
    {
      name: "Juan",
      dni: "11.111.111-1",
      address: "Direccion1",
      phone: "+56 9 1234 5678",
      email: "contact@dominio.cl",
    },
    {
      name: "Juan2",
      dni: "11.111.111-2",
      address: "Direccion1",
      phone: "+56 9 1234 5678",
      email: "contact@dominio.cl",
    },
    {
      name: "Juan3",
      dni: "11.111.111-3",
      address: "Direccion1",
      phone: "+56 9 1234 5678",
      email: "contact@dominio.cl",
    },
    {
      name: "Juan4",
      dni: "11.111.111-4",
      address: "Direccion1",
      phone: "+56 9 1234 5678",
      email: "contact@dominio.cl",
    },
  ]);
  const [data2, setData2] = useState([
    {
      name: "Juan",
      dni: "CCBB98",
      address: "Direccion1",
      phone: "+56 9 1234 5678",
      email: "contact@dominio.cl",
    },
    {
      name: "Juan",
      dni: "BBNNHH",
      address: "Direccion1",
      phone: "+56 9 1234 5678",
      email: "contact@dominio.cl",
    },
  ]);
  useEffect(() => {
    if (selected) {
      form.setFieldValue("name", selected.name);
      form.setFieldValue("dni", selected.dni);
      form.setFieldValue("address", selected.address);
      form.setFieldValue("phone", selected.phone);
      form.setFieldValue("email", selected.email);
    }
  }, [selected]);
  console.log(selected);
  return (
    <>
      <Col span={6} style={{ padding: "30px" }}>
        <Table
          dataSource={data}
          columns={[
            { title: "Nombre", dataIndex: "name" },
            { title: "Rut", dataIndex: "dni" },
          ]}
        />
      </Col>
      <Col span={6} style={{ padding: "30px" }}>
        <Table
          dataSource={data2}
          columns={[
            { title: "Conductor", dataIndex: "name" },
            { title: "Patente", dataIndex: "dni" },
          ]}
        />
      </Col>
      <Col span={6} style={{ padding: "30px" }}>
        <Form
          form={form}
          layout="vertical"
          onFinish={(values) => {
            if (selected) {
            } else {
              setData([...data, values]);
            }
          }}
          initialValues={selected}
        >
          <Form.Item label="Nombre" name="name">
            <Input />
          </Form.Item>

          <Form.Item label="Telefono" name="phone">
            <Input />
          </Form.Item>
          <Form.Item label="Email" name="email">
            <Input />
          </Form.Item>

          <Form.Item>
            <Button
              htmlType="submit"
              type="primary"
              style={{ marginRight: "10px" }}
            >
              Crear
            </Button>
            <Button onClick={() => form.resetFields()}>Limpiar</Button>
          </Form.Item>
        </Form>
      </Col>
      <Col span={6} style={{ padding: "30px" }}>
        <Form
          form={form}
          layout="vertical"
          onFinish={(values) => {
            if (selected) {
            } else {
              setData2([...data, values]);
            }
          }}
          initialValues={selected}
        >
          <Form.Item label="Conductor" name="name_driver">
            <Select placeholder="Selecciona un conductor">
              <Select value="juan">Juan</Select>
              <Select value="juan2">Juan2</Select>
              <Select value="juan3">Juan3</Select>
              <Select value="juan4">Juan4</Select>
            </Select>
          </Form.Item>
          <Form.Item label="Modelo vehiculo" name="modelo">
            <Input />
          </Form.Item>
          <Form.Item label="Patente" name="patente">
            <Input />
          </Form.Item>
          <Form.Item label="Color" name="color">
            <Input />
          </Form.Item>

          <Form.Item>
            <Button
              htmlType="submit"
              type="primary"
              style={{ marginRight: "10px" }}
            >
              Crear
            </Button>
            <Button onClick={() => form.resetFields()}>Limpiar</Button>
          </Form.Item>
        </Form>
      </Col>
    </>
  );
};

export default DriversTrucks;
