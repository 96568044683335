import React, { useState, useEffect } from "react";
import { Col, Form, Input, Button, Table, Select } from "antd";

const Orders = () => {
  const [selected, setSelected] = useState(null);
  const [form] = Form.useForm();
  const [data, setData] = useState([
    {
      name: "VH12345",
      lastname: "VHHAS12",
      type: "Cliente1",
      res: ["Aerosoles", "Baterías ge", "Envases de adhesivo"],
    },
    {
      name: "VH12341",
      lastname: "VHHAS12",
      type: "Cliente2",
      res: ["Baterías ge", "Envases de adhesivo"],
    },
    {
      name: "VH12349",
      lastname: "VHHAS12",
      type: "Cliente3",
      res: ["Aerosoles", "Filtros"],
    },
  ]);
  useEffect(() => {
    if (selected) {
      form.setFieldValue("name", selected.name);
      form.setFieldValue("lastname", selected.lastname);
      form.setFieldValue("type", selected.type);
    }
  }, [selected]);

  return (
    <>
      <Col span={14} style={{ padding: "30px" }}>
        <Table
          dataSource={data}
          columns={[
            { title: "UUID", dataIndex: "name" },
            { title: "Camion", dataIndex: "lastname" },
            { title: "Cliente", dataIndex: "type" },
            {
              title: "Residuos",
              dataIndex: "res",
              render: (x) => (
                <>
                  {x.map((r) => (
                    <>
                      {r}: {r.length}
                      <br />
                    </>
                  ))}
                </>
              ),
            },
            {
              render: (x) => (
                <>
                  <Button type="dashed" size="small">
                    Descargar
                  </Button>
                  <br />
                  <br />
                  <Button size="small" type="primary">
                    Cerrar orden
                  </Button>
                  <br />
                  <br />
                  <Button size="small" type="primary" danger>
                    Eliminar
                  </Button>
                </>
              ),
            },
          ]}
        />
      </Col>
      <Col span={10} style={{ padding: "30px" }}>
        <Form
          form={form}
          layout="vertical"
          onFinish={(values) => {
            if (selected) {
            } else {
              setData([...data, values]);
            }
          }}
          initialValues={selected}
        >
          <Form.Item label="Cliente" name="name">
            <Select
              placeholder="Selecciona un cliente"
              style={{ float: "left" }}
            >
              <Select.Option value="Administrador">Cliente 1</Select.Option>
              <Select.Option value="Administrador">Cliente 2</Select.Option>
              <Select.Option value="Administrador">Cliente 3</Select.Option>
              <Select.Option value="Administrador">Cliente 4</Select.Option>
              <Select.Option value="Administrador">Cliente 5</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item label="Camion" name="lastname">
            <Select
              placeholder="Selecciona un camion"
              style={{ float: "left" }}
            >
              <Select.Option value="Administrador">VHHAS12- Juan</Select.Option>
              <Select.Option value="Administrador">
                VHHAS13- Juan1
              </Select.Option>
              <Select.Option value="Administrador">
                VHHAS14- Juan2
              </Select.Option>
              <Select.Option value="Administrador">VHHAS15- Juan</Select.Option>
              <Select.Option value="Administrador">VHHAS16- Juan</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item label="Residuo" name="type">
            <Select
              placeholder="Selecciona un residuo"
              style={{ width: "200px", float: "left" }}
            >
              <Select.Option value="Aerosoles">Aerosoles</Select.Option>
              <Select.Option value="Filtros">Filtros</Select.Option>
              <Select.Option value="Baterías ge">Baterías ge</Select.Option>
              <Select.Option value="Envases de adhesivo">
                Envases de adhesivo
              </Select.Option>
            </Select>
            <Input
              style={{ width: "100px", marginRight: "10px" }}
              type="number"
              placeholder="cantidad"
            />
            <Button type="primary">Agregar (+)</Button>
          </Form.Item>

          <Form.Item>
            <Button
              htmlType="submit"
              type="primary"
              style={{ marginRight: "10px" }}
            >
              Crear
            </Button>
            <Button onClick={() => form.resetFields()}>Limpiar</Button>
          </Form.Item>
        </Form>
      </Col>
    </>
  );
};

export default Orders;
